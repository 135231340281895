<template lang="pug">
    v-form(v-model="isValid" ref="form" @submit.prevent)
        .w-full.bg-white-pure.l-box-shadow.rounded.p-4
            .max-w-screen-sm.m-auto.justify-center
                h2.text-base.text-center.font-display.mt-6.mb-4
                    | What is your gender?
                .max-w-300.m-auto
                    v-select(
                        outlined
                        dense
                        placeholder="Select option"
                        :items="genderOptions"
                        :rules="[required]"
                        v-model="form.gender"
                    )
                h2.text-base.text-center.font-display.mt-6.mb-4
                    | What is your age?
                .max-w-300.m-auto
                    v-select(
                        outlined dense
                        placeholder="Select option"
                        :items="ageOptions"
                        :rules="[required]"
                        v-model="form.age"
                    )
                h2.text-base.text-center.font-display.mt-6.mb-4
                    | Who did you see at the practice?
                .max-w-300.m-auto
                    v-select(
                        outlined dense
                        placeholder="Select option"
                        :items="doctorOrNurseOptions"
                        :rules="[required]"
                        v-model="form.doctorOrNurse"
                    )
                h2.text-base.text-center.font-display.mt-6.mb-4
                    | Choose a topic to provide feedback on
                ButtonGroup(
                    id="topicGroup"
                    class="flex justify-center"
                    v-model="form.category"
                    :config="btnGroupConfig"
                )
            .flex.mt-6.mb-4
                v-checkbox(
                    hide-details
                    :ripple="false"
                    :rules="[required]"
                    v-model="form.privacyPolicy"
                    class="mt-0 pt-0"
                )
                div
                    | I have read and agree to the
                    a(href="https://google.com" target="_blank" class="font-bold pl-1") Privacy Policy.
                    | &nbsp; I understand the information collected in this survey is confidential and anonymous. This survey has been created by Quality Practice Accreditation (QPA). To find out more about QPA and accreditation
                    a(href="https://www.qpa.health/" target="_blank" class="font-bold pl-1") click here
            .grid.justify-center.mt-6
                v-btn(
                    depressed
                    class="success w-130"
                    :class="{'disabled pointer-events-none': loading}"
                    @click="goToNextForm()"
                )
                    fa-icon(v-if="loading" icon="spinner" spin class="mr-2 text-white-pure")
                    | {{ buttonText }}
                    fa-icon(v-if="!loading" icon="chevron-right" class="ml-2 text-white-pure")
</template>

<script>
import ButtonGroup from '@/components/basic/ButtonGroup'
import {FormMixins, PreSurveyMixins} from '@/components/shared/mixins/formSave'
import {FormRules} from '@/components/shared/mixins/formMixins'
import GET_CATEGORIES_BY_FORM_SET_ID from '@/graphql/queries/getCategoriesByFormSetId.gql'
import camelcaseKeys from 'camelcase-keys'
import GET_TENANT from '@/graphql/queries/getTenant.gql'
import GET_CONSTANTS from '@/graphql/queries/getConstants.gql'
import {ConstantTypeEnum} from '@/utils/enums'
import {mapActions, mapGetters} from 'vuex'
import GET_TENANT_BY_SHORT_NAME from '@/graphql/queries/getTenantByShortName.gql'
import GET_FORM_SET_ID_BY_SHORT_NAME from '@/graphql/queries/getFormSetIdByShortName.gql'
import {FORM_TYPES, PRE_SURVEY_TEXT} from '@/modules/constants'

export default {
    name: 'PreSurveyForm',
    components: {ButtonGroup},
    mixins: [FormMixins, FormRules, PreSurveyMixins],
    props: {
        formData: {
            type: Object,
            default: () => {
            }
        },
        shortName: {
            type: String,
            default: ''
        }
    },
    apollo: {
        tenant: {
            query: GET_TENANT,
            variables() {
                return {
                    tenantId: this.tenantId,
                }
            },
            skip() {
                // we skip this get if we don't have a tenantId OR we are getting by the shortname
                return !this.tenantId || !!this.shortName
            },
            result({data: {tenant}}) {
                this.tenant = camelcaseKeys(tenant, {deep: true})
                this.setParentTenantId(this.tenant?.parentTenantId)
                this.setFormTitle(`${PRE_SURVEY_TEXT} ${tenant.name}`)
                this.$store.dispatch('setTenantLogo', this.tenant.logo)
                if (this.$apollo.queries.categories) this.$apollo.queries.categories.refetch()
                this.setTenant(this.tenant)
            }
        },
        categories: {
            query: GET_CATEGORIES_BY_FORM_SET_ID,
            variables() {
                const tenantIdOrCompare = [{
                    tenant_id: {_eq: this.tenantId}
                }]

                if (this.parentTenantId) {
                    tenantIdOrCompare.push({
                        tenant_id: {_eq: this.parentTenantId}
                    })
                }

                return {
                    tenantIdOrCompare,
                    formSetId: this.formSetId
                }
            },
            skip() {
                return !this.tenantId || !this.formSetId || !this.parentTenantId
            },
            result({data: {categories}}) {
                const categoryList = camelcaseKeys(categories, {deep: true})
                this.categories = categoryList.map(cl => {
                    return {
                        text: cl.name,
                        value: String(cl.categoryId)
                    }
                })
                if (this.categories.length) this.form.category = this.categories[0].value
                this.btnGroupConfig.categories = this.categories
                this.loading = false
            }
        },
        constants: {
            query: GET_CONSTANTS,
            variables() {
                return {
                    tenantId: this.tenantId,
                }
            },
            skip() {
                return !this.tenantId
            },
            result({data: {constants}}) {
                this.constants = camelcaseKeys(constants, {deep: true})
            }
        },

        // functions to get the form_set_id and tenant_info by the shortName
        tenantByShortName: {
            query: GET_TENANT_BY_SHORT_NAME,
            variables() {
                return {
                    shortName: this.shortName,
                }
            },
            skip() {
                return this.tenantId || !this.shortName
            },
            result({data: { tenantByShortName }}) {
                //TODO: do something if we don't find anything
                if (!tenantByShortName.length) return

                this.tenant = camelcaseKeys(tenantByShortName, {deep: true})[0]
                this.tenantId = this.tenant?.tenantId
                this.setParentTenantId(this.tenant?.parentTenantId)
                this.setFormTitle(`${PRE_SURVEY_TEXT} ${this.tenant.name}`)
                this.setTenant(this.tenant)
            }
        },
        formSet: {
            query: GET_FORM_SET_ID_BY_SHORT_NAME,
            variables() {
                return {
                    shortName: this.shortName,
                    parentTenantId: this.parentTenantId
                }
            },
            skip() {
                return !!this.formSetId || !this.shortName || !this.parentTenantId
            },
            result({data: { formSet }}) {
                //TODO: do something if we don't find anything
                if (!formSet.length) return

                this.formSet = camelcaseKeys(formSet || [], {deep: true})[0]
                this.formSetId = this.formSet?.formSetId
                this.$apollo.queries.categories.refetch()
                this.$apollo.queries.constants.refetch()
            }
        },
    },
    data() {
        return {
            loading: true,
            checkbox: false,
            tenantId: this.$route.query.tenant || this.$route.query?.ti,
            formId: this.$route.query.f,
            formVersion: this.$route.query.v,
            fullSurvey: this.$route.query.fs,
            formSetId: this.$route.query.fsi,
            practiceName: this.$route.query.practiceName,
            isKiosk: !!this.$route.params?.kiosk,
            disabled: false,
            categories: [],
            isValid: true,
            tenant: {},
            categoryButton: 'center',
            changeButton: 'left',
            btnGroupConfig: {
                categories: []
            },
            defaultForm: {
                age: '',
                gender: '',
                category: '',
                change: '',
                privacyPolicy: '',
                confidentialityAgreement: '',
                surveyType: 'BOTH',
                // TODO: remove this magic id when we move this PreSurvey to the DB
                formTypeId: '21d5e65d-5b9f-4ae0-928f-c1845bb738ec', // form type of Survey
                doctorOrNurse: ''
            },
        }
    },
    async mounted() {
        if (this.tenantId) await this.$store.commit('SET_TENANT_ID', this.tenantId)
        if (this.shortName) await this.setFormTitle(`Thank you for helping us improve ${this.tenant.name}`)

        this.clearValidate()
    },
    methods: {
        ...mapActions(['setCategory', 'setParentTenantId', 'setFormTitle', 'setTenant']),
        validate() {
            this.isValid = this.$refs.form.validate()
            return this.isValid
        },
        clearValidate() {
            this.isValid = true
            return this.isValid
        },
        async goToNextForm() {
            if (this.validate()) {
                this.disabled = true
                // first we save the form to the database
                try {
                    this.forms = [this.form]
                    const formAnswer = await this.saveForm()
                    this.setCategory(this.categoryName)
                    const { data: { saveSurveyAnswers: { answer } }} = camelcaseKeys(formAnswer, {deep: true})
                    await this.$router.push({
                        name: this.getNextFormRouteName(this.isKiosk, this.onLastForm(), this.shortName),
                        query: {
                            tenant: this.tenantId,
                            category: this.form.category,
                            change: this.form.change,
                            v: this.formVersion,
                            f: this.formId,
                            fs: this.fullSurvey,
                            fsi: this.formSetId,
                            ks: this.form?.doctorOrNurse,
                            practiceName: this.practiceName
                        },
                        params: {
                            formAnswerId: answer.formAnswerId
                        }
                    })
                } catch (e) {
                    console.error(e)
                } finally {
                    this.disabled = false
                }
            }
        }
    },
    computed: {
        ...mapGetters(['staticChoices', 'parentTenantId']),
        form() {
            return this.formData || this.defaultForm
        },
        buttonText() {
            return this.disabled ? 'Saving...' : this.loading ? 'Loading...' : 'Next'
        },
        ageOptions() {
            return this.constants?.filter(c => c.group === ConstantTypeEnum.Age)
        },
        genderOptions() {
            return this.constants?.filter(c => c.group === ConstantTypeEnum.Gender)
        },
        categoryName() {
            const categories = this.btnGroupConfig.categories
            return categories?.find(c => c.value === this.form.category)?.text || ''
        },
        doctorOrNurseOptions() {
            return this.staticChoices['form.doctorOrNurse']
        }
    },
    watch: {
        async tenantId() {
            if (this.tenantId) {
                await this.$apollo.queries.categories.refetch()
                await this.$apollo.queries.tenant.refetch()
            }
        }
    }
}
</script>

